import React, { useState, useLayoutEffect } from "react"
import type { PageProps } from "gatsby"
import { graphql } from "gatsby"
import styled from "styled-components"

import type { Query, MarkdownRemarkFrontmatter } from "Types/GraphQL"
import type Post from "Types/Post"
import useSiteMetadata from "Hooks/useSiteMetadata"
import Layout from "Layouts/layout"
import SEO from "Components/seo"
import PostGrid from "Components/postGrid"
import CategoryFilter from "Components/catetgoryFilter"
import { Link } from "gatsby"
import Category from "Styles/category"
import Bgimg from "Pages/images/balloon_girl_sm.gif"
import Fsimg from "Pages/images/pencil_2.png"
import Mcimg from "Pages/images/mails.png"
import Cbimg from "Pages/images/bouquet_2.png"
console.log(Bgimg)

const Home = ({
  pageContext,
  data,
}: PageProps<Query, MarkdownRemarkFrontmatter>) => {
  const [posts, setPosts] = useState<Post[]>([])
  const currentCategory = pageContext.category
  const postData = data.allMarkdownRemark.edges

  useLayoutEffect(() => {
    const filteredPostData = currentCategory
      ? postData.filter(
          ({ node }) => node?.frontmatter?.category === currentCategory
        )
      : postData

    filteredPostData.forEach(({ node }) => {
      const { id } = node
      const { slug } = node?.fields!
      const { title, desc, date, category, thumbnail, alt } = node?.frontmatter!
      const { childImageSharp } = thumbnail!

      setPosts(prevPost => [
        ...prevPost,
        {
          id,
          slug,
          title,
          desc,
          date,
          category,
          thumbnail: childImageSharp?.id,
          alt,
        },
      ])
    })
  }, [currentCategory, postData])

  const site = useSiteMetadata()
  const postTitle = currentCategory || site.postTitle

  return (
    <Layout>
      <SEO
       title="UX Product Design Leadership & Strategy | Christina - Atlanta" 
       description="Seasoned UX and product designer leading B2B and Saas teams with two decades of holistic experience."
        />
      <Main>
        <Content>
          {/*<PostTitle>{postTitle}</PostTitle>*/}
        <div className="intro">
          <p>
          <Link to="/design" rel="nofollow">Product design</Link> and <Link to="/strategy" rel="nofollow">strategy</Link> through the lens of collaboration, holistic vision, and pragmatism.
          </p>
          {/*<img src={Bgimg} alt="Logo" />*/}
        </div>
        <div className="intro2">
          <h2>Design Case Studies</h2>
          <p><a href="mailto:clingga@gmail.com">Email me for password</a></p>
          </div>
          <div className="work">
          <div>
          <img src={Fsimg} alt="Pencil Spot Illustration" />
          <h3>Fullstory</h3>
          <br/><p>Staff Product Designer & Lead UX</p> 
          <br/><Link to="/design/templates" rel="nofollow">Templates</Link>
          <br/><Link to="/design/heatmaps" rel="nofollow">Heatmaps</Link>
          <br/><Link to="/design/journeys" rel="nofollow">Journeys</Link>
          <br/><Link to="/design/funnels" rel="nofollow">Funnels</Link>
          </div>
          <div>
          <div><img src={Mcimg} alt="Bouquet Spot Illustration" /></div>
          <h3>Mailchimp</h3>
          <br/><p>Senior Product Designer</p>
          <br/><a href="/design/landing-pages" rel="nofollow">Landing Pages for Commerce</a>
          <br/><Link to="/design/ecommerce-integration" rel="nofollow">e-Commerce Integration</Link>
          </div>
          <div>
          <p><img src={Cbimg} alt="Bouquet Spot Illustration" /></p>
          <h3>CareerBuilder</h3>
          <br/><p>Senior UI Designer</p>
          <br/><Link to="/design/semantic-search" rel="nofollow">ML/AI Semantic Search</Link>
          <br/><Link to="/design/resume-database" rel="nofollow">Resume Database</Link>
          </div>
          </div>
        </Content>
      </Main>
    </Layout>
  )
}

const Main = styled.main`
  min-width: var(--min-width);
  min-height: calc(100vh - var(--nav-height) - var(--footer-height));
  padding-bottom: var(--sizing-xxl);
  
  .intro {
    background-image: url(${Bgimg});
  }
  .intro2 {
    padding-top: var(--sizing-base);
    padding-bottom: var(--sizing-xl);
    h2 {
      font-family: var(--font-display);
      font-size: var(--text-xxl);
      line-height: var(--text-lg);
      @media (max-width: ${({ theme }) => theme.device.sm}) {
        font-size: var(--text-xl);
      }
    }
    p {
      line-height: var(--text-base);
    }
  }
  .work {
    display: grid;
      grid-gap: var(--grid-gap-lg);
  grid-template-columns: repeat(3, 1fr);
      list-style: none;
      @media (max-width: ${({ theme }) => theme.device.md}) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: ${({ theme }) => theme.device.sm}) {
        grid-template-columns: repeat(1, 1fr);
      }
  > div {
      padding: var(--sizing-md);
      padding-bottom: var(--sizing-lg);
      border-radius: var(--sizing-md);
      border: solid 1px #b5c0e0;
      font-size: var(--text-base);
      h3 {
        font-family: var(--font-display);
        font-size: var(--text-lg);
        line-height: var(--text-base);
        padding-bottom: 0px;
        @media (max-width: ${({ theme }) => theme.device.sm}) {
          font-size: var(--text-md);
        }
      }
      img {
        width: 35%;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: ${({ theme }) => theme.device.sm}) {
          width: 20%;
        }
      }
      @media (max-width: ${({ theme }) => theme.device.md}) {
      }
      @media (max-width: ${({ theme }) => theme.device.sm}) {
        border: none;
        padding: 1rem 0;
        height: 100%;
        border-top: solid 1px var(--color-gray-4);
        border-radius: 0;
      }
      :nth-child(1) {
        @media (max-width: ${({ theme }) => theme.device.sm}) {
          background-color: transparent;
        }
        h4 {
          color: var(--color-gray-4);
          a {
            color: var(--color-gray-4);
          }
          @media (max-width: ${({ theme }) => theme.device.sm}) {
            color: black;
          }
        }
        }
      :nth-child(2) {
      background-color: #ddd6f3;
      @media (max-width: ${({ theme }) => theme.device.sm}) {
        background-color: transparent;
      }
      }
      :nth-child(3) {
        background-color: var(--color-gray-2);
        @media (max-width: ${({ theme }) => theme.device.sm}) {
          background-color: transparent;
        }
        }
      background-color: #f9d3bb;
      @media (max-width: ${({ theme }) => theme.device.sm}) {
        background-color: transparent;
      }
  }
  a {
    font-size: var(--text-md);
    line-height: var(--text-md);
    box-shadow: inset 0 0 0 #fff, inset 0 -1rem 0 var(--color-highlighter);
    text-decoration: none;
    &:hover{
      color: var(--color-gray-5);
    }
    @media (max-width: ${({ theme }) => theme.device.sm}) {
      font-size: 1.4rem;
      box-shadow: inset 0 0 0 #fff, inset 0 -1rem 0 var(--color-highlighter);
    }
  }  
}
}
`

const Content = styled.div`
  box-sizing: content-box;
  width: 87.5%;
  max-width: var(--width);
  padding-top: var(--sizing-lg);
  padding-bottom: var(--sizing-lg);
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.device.sm}) {
    padding-top: var(--grid-gap-lg);
    width: 87.5%;
  }
`


export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { tag: { eq: "Selected" } }}
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            category
            date(formatString: "YYYY-MM-DD")
            desc
            thumbnail {
              childImageSharp {
                id
              }
              base
            }
            alt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Home
